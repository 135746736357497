import * as React from "react"

// Styles
import "../../styles/presskit/presskit_section_game-description.scss"

// markup
const GameDescriptionSection = () => {
    return (
        <div className="presskit_section presskit_section_game-description">

            <div className="wrapper-narrow">

                <h1>
                    Game Description
                </h1>

                <p>
                    Take to the mountains in <em>Super Snow Tubes</em>, a satisfyingly cute, retro-inspired fun racer{/*(for up to 4 players)*/}, starring a cast of quirky and adorable animal buddies in a fun and fierce battle for first place. <em>Ready, set, tube!</em>
                </p>

                <blockquote>
                    <q>
                        Retro-cute animal racing in a wholesome homage to the winter sports classics of the 90s.
                    </q>
                </blockquote>

                <p>
                    {/* Whether on your own, or with up to 4 players in local split-screen multiplayer mode,  */}
                    Join Polar, Pengu, Seal and their snow tubing animal buddies in a fiery competition that takes you through a wonderful world of colorful race courses, ripe with hidden shortcuts, on a mission to answer the ultimate question:
                </p>

                <blockquote>
                    <q>
                        Who will become the Champ of Snow Tube Mountain?
                    </q>
                </blockquote>

                <p>
                    With its cute and charming pixel art style, its extra catchy soundtrack{/*, its intuitive controls*/}, and its simple, yet addictive gameplay,  <em>Super Snow Tubes</em> is a wholesome homage to the winter sports classics of the early to late 90s - such as Ski or Die, Ski Free and Snowboard Kids. A must for anyone who loved these titles back in the day!
                </p>

            </div>

        </div>
    )
}

export default GameDescriptionSection