import * as React from "react"

// Styles
import "../../styles/presskit/presskit_section_top.scss"

// markup
const TopSection = () => {
    return (
        <div className="presskit_section_top">

            <div className="wrapper-wide">

                <h1>
                    Press Kit
                </h1>

                <h2>
                    Super Snow Tubes
                </h2>

                <p className="intro">
                    Retro-cute animal racing in a wholesome homage to the winter sports classics of the 90s.
                </p>

                <p>
                    Take to the mountains in <em>Super Snow Tubes</em>, a satisfyingly cute, retro-inspired fun racer, starring a cast of quirky and adorable animal buddies in a fun and fierce battle for first place. <em>Ready, set, tube!</em>
                </p>

            </div>

        </div>
    )
}

export default TopSection