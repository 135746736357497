import * as React from "react"

// Styles
import "../../styles/presskit/presskit_section_fact-sheet.scss"

// markup
const FactSheetSection = () => {

    // The items for the fact sheet
    const itemsA = [
        {
            label: "Title",
            value: "Super Snow Tubes"
        },
        {
            label: "Strapline",
            value: "A satisfyingly cute, retro-inspired snow racer, starring a cast of quirky and adorable animal buddies in a fun and fierce battle for first place!"
        },
        {
            label: "Genre",
            value: "Racer (Fun/Kart/Snow/Combat/Arcade)"
        },
        {
            label: "Graphics",
            value: "Pixel Art, 2D, Top-Down, Retro-Cute"
        },
        {
            label: "Key Words",
            value: "Retro, Cute, Wholesome, Animals, Winter, Snow, Arcade"
        },
    ];

    // The items for the fact sheet
    const itemsB = [
        {
            label: "Developer",
            value: "GameDevMix (based in Leeds, UK)"
        },
        {
            label: "Publisher",
            value: "Same as Developer"
        },
        {
            label: "Platform(s)",
            value: "PC - Steam (Windows, Mac)"
            //value: "PC via Steam (Windows, Mac) | Nintendo Switch"
        },
        {
            label: "Release Date(s)",
            value: "PC TBA"
            //value: "TBA PC | TBA Nintendo Switch"
        },
        {
            label: "Price (Regular)",
            value: "USD TBA | EUR TBA | GBP TBA"
            //value: "USD 14.99 | EUR 13.99 | GBP 11.99"
        },
        {
            label: "Rating(s)",
            value: "Not yet rated"
        },
        {
            label: "Websites",
            value:
                <>
                    <a
                        href="https://SuperSnowTubes.com"
                        target="_blank"
                        rel="noreferrer"
                    >
                        SuperSnowTubes.com
                    </a><br />
                    <a
                        href="https://GameDevMix.com"
                        target="_blank"
                        rel="noreferrer"
                    >
                        GameDevMix.com
                    </a>
                </>
        },
    ];

    return (
        <div className="presskit_section presskit_section_fact-sheet">

            <div className="wrapper-narrow">

                <h1>
                    Fact Sheet
                </h1>

                <h2>
                    Game
                </h2>

                <ul className="items">
                    {itemsA.map(item => (

                        <li
                            className="item"
                            key={item.label}
                        >

                            <div className="item-label">
                                {item.label}:
                            </div>

                            <div className="item-value">
                                {item.value.split
                                    ? item.value
                                        .split(" | ")
                                        .map((line, i) =>
                                            <div key={i}>
                                                {line}
                                            </div>
                                        )
                                    : item.value
                                }
                            </div>

                        </li>

                    ))}
                </ul>

                <h2>
                    More
                </h2>

                <ul className="items">
                    {itemsB.map(item => (

                        <li
                            className="item"
                            key={item.label}
                        >

                            <div className="item-label">
                                {item.label}:
                            </div>

                            <div className="item-value">
                                {item.value.split
                                    ? item.value
                                        .split(" | ")
                                        .map((line, i) =>
                                            <div key={i}>
                                                {line}
                                            </div>
                                        )
                                    : item.value
                                }
                            </div>

                        </li>

                    ))}
                </ul>

            </div>

        </div>
    )
}

export default FactSheetSection