import * as React from "react"

// Styles
import "../../styles/presskit/presskit_section_fact-sheet.scss"
import "../../styles/presskit/presskit_section_contact.scss"

// markup
const ContactSection = () => {

    // The items for the contacts
    const itemsA = [
        {
            label: "Press Contact",
            labelColor: "#444",
            value: "Ben@GameDevMix.com"
        },
        {
            label: "Websites",
            labelColor: "#444",
            value:
                <>
                    <a
                        href="https://SuperSnowTubes.com"
                        target="_blank"
                        rel="noreferrer"
                    >
                        SuperSnowTubes.com
                    </a><br />
                    <a
                        href="https://GameDevMix.com"
                        target="_blank"
                        rel="noreferrer"
                    >
                        GameDevMix.com
                    </a>
                </>
        },
    ];

    // The items for the contacts
    const itemsB = [
        {
            label: "Twitter",
            labelColor: "#55ACED",
            value:
                <a
                    href="https://twitter.com/GameDevMix"
                    target="_blank"
                    rel="noreferrer"
                >
                    @GameDevMix
                </a>
        },
        {
            label: "Instagram",
            labelColor: "#E4405F",
            value:
                <a
                    href="https://instagram.com/GameDevMix"
                    target="_blank"
                    rel="noreferrer"
                >
                    @GameDevMix
                </a>
        },
        {
            label: "Tik Tok",
            labelColor: "#01EAE4",
            value:
                <a
                    href="https://www.tiktok.com/@GameDevMix"
                    target="_blank"
                    rel="noreferrer"
                >
                    @GameDevMix
                </a>
        },
        {
            label: "Facebook",
            labelColor: "#4064AC",
            value:
                <a
                    href="https://facebook.com/GameDevMix"
                    target="_blank"
                    rel="noreferrer"
                >
                    GameDevMix Page
                </a>
        },
        // {
        //     label: "YouTube",
        //     value:
        //         <a
        //             href="https://www.youtube.com/channel/UC9V9OI9iAAa0p3HIy53Q5-g"
        //             target="_blank"
        //             rel="noreferrer"
        //         >
        //             GameDevMix YouTube Channel
        //         </a>
        // },
    ];

    return (
        <div className="presskit_section presskit_section_contact">

            <div className="wrapper-narrow">

                <h1>
                    Contact
                </h1>

                <h2>
                    Email / Web
                </h2>

                <ul className="items">
                    {itemsA.map(item => (

                        <li
                            className="item"
                            key={item.label}
                        >

                            <div
                                className="item-label"
                                style={{ color: item.labelColor }}
                            >
                                {item.label}:
                            </div>

                            <div className="item-value">
                                {item.value.split
                                    ? item.value
                                        .split(" | ")
                                        .map((line, i) =>
                                            <div key={i}>
                                                {line}
                                            </div>
                                        )
                                    : item.value
                                }
                            </div>

                        </li>

                    ))}
                </ul>

                <h2>
                    Socials
                </h2>

                <ul className="items">
                    {itemsB.map(item => (

                        <li
                            className="item"
                            key={item.label}
                        >

                            <div
                                className="item-label"
                                style={{ color: item.labelColor }}
                            >
                                {item.label}:
                            </div>

                            <div className="item-value">
                                {item.value.split
                                    ? item.value
                                        .split(" | ")
                                        .map((line, i) =>
                                            <div key={i}>
                                                {line}
                                            </div>
                                        )
                                    : item.value
                                }
                            </div>

                        </li>

                    ))}
                </ul>

            </div>

        </div>
    )
}

export default ContactSection