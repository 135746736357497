import * as React from "react"

// Styles
import "../../styles/presskit/presskit_section_key-features.scss"

// markup
const KeyFeaturesSection = () => {
    return (
        <div className="presskit_section presskit_section_key-features">

            <div className="wrapper-narrow">

                <h1>
                    Key Features
                </h1>

                <ul>
                    <li>
                        <div className="feature-label">
                            Characters
                        </div>
                        Play a quirky cast of <strong>adorable animal riders</strong>, from penguin to polar bear, each with their very own, unique handling - based on the game's Star Stats system
                    </li>
                    <li>
                        <div className="feature-label">
                            Levels
                        </div>
                        Race and explore a wonderful world of <strong>colorful mountain courses</strong>, ripe with hidden shortcuts just waiting to be discovered
                    </li>
                    <li>
                        <div className="feature-label">
                            Controls
                        </div>
                        <strong>Overtake your competition</strong> in a fun and fierce battle for first place - utilising intuitive controls that are easy to pick up, but difficult to master
                    </li>
                    <li>
                        <div className="feature-label">
                            Obstacles
                        </div>
                        Outmaneuver fierce foes, <strong>avoid bumpy blockades</strong>, and stay on track at all cost to maximise your tube's speed and get ahead of the field
                    </li>
                    <li>
                        <div className="feature-label">
                            Hub World
                        </div>
                        Take a break from the slopes and relax between races in Tube Town, the game's <strong>extra cozy hub world</strong> - located right at the bottom of Snow Tube Mountain
                    </li>
                    <li>
                        <div className="feature-label">
                            Mini Games
                        </div>
                        Save up coins, hit the shops, and unlock a bonanza of <strong>snow tube themed mini games</strong> and other goodies that you do not want to miss
                    </li>
                    <li>
                        <div className="feature-label">
                            Soundtrack
                        </div>
                        Immerse yourself in the game's super happy, extra bouncy, <strong>ultra catchy soundtrack</strong>, whether on the slopes or at the juke box; earworms guaranteed
                        {/* 
                            Explore the diversity of each level
                            Enjoy a diverse soundtrack
                            with unique tracks at every turn 
                        */}
                    </li>
                    <li>
                        <div className="feature-label">
                            Art Style
                        </div>
                        Enjoy a colorful, <strong>retro-cute pixel art style</strong> with a modern spin - merging the best of then and now - in a wholesome homage to the 90s childhood
                    </li>

                    {/* <li>
                        Battle and sabotage your competition with item pickups, shots and shields
                    </li> */}

                    {/* <li>
                        Race giant bosses, just like you remember it from the kart racers of the Nintendo 64 era
                    </li> */}

                    {/* Unlock and master a bonanza of super fun mini games */}

                </ul>

            </div>

        </div>
    )
}

export default KeyFeaturesSection