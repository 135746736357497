import * as React from "react"

// SEO
import Seo from '../components/Seo'

// Site
import SiteHeader from '../components/SiteHeader'
import SiteFooter from '../components/SiteFooter'

// Sections
import SectionTop from '../components/presskit/PressKit_Section-Top'
import SectionFactSheet from '../components/presskit/PressKit_Section-FactSheet'
import SectionGameDescription from '../components/presskit/PressKit_Section-GameDescription'
import SectionKeyFeatures from '../components/presskit/PressKit_Section-KeyFeatures'
//import SectionVideoTrailer from '../components/presskit/PressKit_Section-VideoTrailer'
import SectionScreenshots from '../components/presskit/PressKit_Section-Screenshots'
import SectionContact from '../components/presskit/PressKit_Section-Contact'

import "@fontsource/cabin-condensed/400.css"
import "@fontsource/cabin-condensed/500.css"
import "@fontsource/cabin-condensed/600.css"
import "@fontsource/cabin-condensed/700.css"

import "@fontsource/open-sans/400.css"
import "@fontsource/open-sans/600.css"
import "@fontsource/open-sans/700.css"
import "@fontsource/open-sans/800.css"

import "../styles/reset.scss"
import "../styles/styles.scss"
import "../styles/presskit/presskit.scss"

// styles
const pageStyles = {
    color: "#232129",
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

// markup
const PressKitPage = () => {

    return (
        <main className="presskit-page" style={pageStyles}>

            {/* SEO */}
            <Seo
                title="Press Kit"
                description=""
                slug="presskit"
            />

            <SiteHeader />

            <SectionTop />

            <SectionFactSheet />

            <SectionGameDescription />

            <SectionKeyFeatures />

            {/* <SectionVideoTrailer /> */}

            <SectionScreenshots />

            <SectionContact />

            <SiteFooter />

        </main>
    )
}

export default PressKitPage
