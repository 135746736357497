import * as React from "react"

import { StaticImage } from 'gatsby-plugin-image';

// Styles
import "../../styles/presskit/presskit_section-screenshots.scss"

// markup
const ScreenshotsSection = () => {

    return (
        <div className="presskit_section presskit_section_screenshots">

            <div className="wrapper-narrow">

                <h1>
                    Screenshots
                </h1>

            </div>

            <div className="wrapper-wide">

                <div className="grid">

                <div className="grid-element">
                        <StaticImage
                            alt="Screenshot"
                            src="../../images/presskit/screenshots/screenshot_purple.png"
                        // layout="fullWidth"
                        />
                        <div className="label">
                            Purple Forest
                        </div>
                    </div>

                    <div className="grid-element">
                        <StaticImage
                            alt="Screenshot"
                            src="../../images/presskit/screenshots/screenshot_snow.png"
                        // layout="fullWidth"
                        />
                        <div className="label">
                            Snowflake Forest
                        </div>
                    </div>

                    {/* <div className="grid-element">
                        <StaticImage
                            alt="Screenshot"
                            src="../../images/presskit/screenshots/screenshot_rider-select.png"
                            layout="fullWidth"
                        />
                        <div className="label">
                            Rider Select
                        </div>
                    </div> */}

                    <div className="grid-element">
                        <StaticImage
                            alt="Screenshot"
                            src="../../images/presskit/screenshots/screenshot_grass.png"
                            layout="fullWidth"
                        />
                        <div className="label">
                            Green Grass Gardens
                        </div>
                    </div>

                    <div className="grid-element">
                        <StaticImage
                            alt="Screenshot"
                            src="../../images/presskit/screenshots/screenshot_japan.png"
                            layout="fullWidth"
                        />
                        <div className="label">
                            {/* Nihon Nippon */}
                            {/* Ninja Nation */}
                            Nippon Ninjas
                        </div>
                    </div>

                    <div className="grid-element">
                        <StaticImage
                            alt="Screenshot"
                            src="../../images/presskit/screenshots/screenshot_arctic.png"
                            layout="fullWidth"
                        />
                        <div className="label">
                            Arctic Alley Valley
                        </div>
                    </div>

                    {/* <div className="grid-element">
                        <StaticImage
                            alt="Screenshot"
                            src="../../images/presskit/screenshots/screenshot_course-select.png"
                            layout="fullWidth"
                        />
                        <div className="label">
                            Course Select
                        </div>
                    </div> */}

                    <div className="grid-element">
                        <StaticImage
                            alt="Screenshot"
                            src="../../images/presskit/screenshots/screenshot_desert.png"
                            layout="fullWidth"
                        />
                        <div className="label">
                            Prickly Pear Parade
                        </div>
                    </div>

                    <div className="grid-element">
                        <StaticImage
                            alt="Screenshot"
                            src="../../images/presskit/screenshots/screenshot_beach.png"
                            layout="fullWidth"
                        />
                        <div className="label">
                            Pirate Beach
                        </div>
                    </div>

                    <div className="grid-element">
                        <StaticImage
                            alt="Screenshot"
                            src="../../images/presskit/screenshots/screenshot_mines.png"
                            layout="fullWidth"
                        />
                        <div className="label">
                            Mine Cart Mayhem
                        </div>
                    </div>

                </div>

            </div>


        </div>


    )
}

export default ScreenshotsSection